import { FileWithPath, useAppSelector } from '@oproma/prividox-store';
import clsx from 'clsx';
import { Progress } from 'reactstrap';
import { Icon } from '../icon.component';
import { prettyBytes } from '../utils';

export interface FileTreeNode {
  name: string;
  type: 'file' | 'folder';
  children?: FileTreeNode[];
  file?: FileWithPath;
  filepath?: string;
  level: number;
}

export const FileTreeNodes = ({
  node,
  onRemove,
  index,
  processUid,
  cancelUpload,
}: {
  node: FileTreeNode;
  onRemove: (filepath: string, index: number) => void;
  index: number;
  processUid?: string;
  cancelUpload?: (processUid: string) => void;
}) => {
  // Get both old and new upload states for compatibility
  const legacyProgress = useAppSelector(
    (state) => state.fileManager.entityCreationProgress[processUid || ''] || 0,
  );
  const legacyFileUploading = useAppSelector(
    (state) => state.fileManager.fileUploading[processUid || ''],
  );

  // New upload states
  const uploadState = useAppSelector((state) =>
    processUid ? state.fileManager.uploadStates[processUid] : undefined,
  );

  // Determine the actual states to use
  const progress = uploadState?.progress ?? legacyProgress;
  const status =
    uploadState?.status ??
    (legacyProgress === -1
      ? 'error'
      : legacyProgress === -2
      ? 'cancelled'
      : legacyFileUploading
      ? 'uploading'
      : 'idle');
  const error = status === 'error';
  const cancelled = status === 'cancelled';
  const isUploading = status === 'uploading';

  const getProgressColor = () => {
    switch (status) {
      case 'cancelled':
        return 'warning';
      case 'error':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const getProgressText = () => {
    switch (status) {
      case 'cancelled':
        return 'Cancelled';
      case 'error':
        return uploadState?.error || 'Error';
      case 'uploading':
        return progress > 0 ? `${progress}%` : '';
      default:
        return '';
    }
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    if (processUid && cancelUpload && isUploading) {
      cancelUpload(processUid);
    }
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    if (processUid && cancelUpload && isUploading) {
      cancelUpload(processUid);
    }
    if (node.filepath) {
      onRemove(node.filepath, index);
    }
  };

  return (
    <div
      className="file-tree-node"
      style={{ marginLeft: `${node.level * 20}px` }}
    >
      <div className="d-flex align-items-center">
        <Icon
          name={node.type === 'folder' ? 'folder' : 'file'}
          className="me-2"
        />
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <span
              className={clsx({
                'text-danger': error,
                'text-muted': cancelled,
              })}
            >
              {node.name}
            </span>
            {node.type === 'file' && node.file && (
              <span className="text-muted ms-2">
                ({prettyBytes(node.file.size)})
              </span>
            )}
          </div>
          {node.type === 'file' && processUid && status !== 'idle' && (
            <div className="mt-1" style={{ maxWidth: '200px' }}>
              <Progress
                striped={isUploading}
                animated={isUploading}
                className="progress-sm"
                color={getProgressColor()}
                value={cancelled || error ? 100 : progress}
              >
                <small
                  className="progress-text"
                  title={error ? uploadState?.error : undefined}
                >
                  {getProgressText()}
                </small>
              </Progress>
            </div>
          )}
        </div>
        {node.type === 'file' && node.filepath && (
          <div className="ms-auto d-flex align-items-center">
            {isUploading && processUid && cancelUpload && (
              <button
                type="button"
                className="btn btn-sm btn-icon btn-soft-warning me-1"
                onClick={handleCancel}
                title="Cancel upload"
              >
                <Icon name="x-circle" />
              </button>
            )}
            <button
              type="button"
              className="btn btn-sm btn-icon btn-soft-danger"
              onClick={handleRemove}
              disabled={status === 'completed'}
              title={isUploading ? 'Cancel and remove' : 'Remove'}
            >
              <Icon name="trash" />
            </button>
          </div>
        )}
      </div>
      {node.children?.map((child, childIndex) => (
        <FileTreeNodes
          key={childIndex}
          node={child}
          onRemove={onRemove}
          index={index + childIndex}
          processUid={processUid}
          cancelUpload={cancelUpload}
        />
      ))}
    </div>
  );
};
