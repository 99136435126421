import {
  AuthLayout,
  AuthLogo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Head,
  Icon,
  PreviewCard,
  retrieveApiErrorMessage,
  useLockForm,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import { logout, resetError, useAppSelector } from '@oproma/prividox-store';
import { Link, useNavigate, useSearchParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';

const LockPage = () => {
  const { t } = useTranslation();
  const [queryParams] = useSearchParams();
  const from = queryParams.get('from');
  const {
    errors,
    email,
    mfaRef,
    requiresMFA,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerPassword,
    registerMFA,
    displayPasswordText,
    toggleDisplayingPasswordText,
  } = useLockForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, expiredAuth, isAuthenticated, error } = useAppSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !expiredAuth) {
      navigate(from || '/', {
        replace: true,
      });
    }
  }, [isAuthenticated, expiredAuth, navigate]);

  return (
    <>
      <Head heading={t('AUTH.LOCK.TITLE')} />
      <AuthLayout>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <AuthLogo />
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">{t('AUTH.LOCK.HEADING')}</BlockTitle>
                <BlockDes>
                  <p>
                    {t('AUTH.LOCK.SUBHEADING', {
                      email,
                    })}
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            {error && (
              <Alert className="alert-icon" color="danger">
                <Icon name="cross-circle" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(retrieveApiErrorMessage(error.message)),
                  }}
                ></p>
              </Alert>
            )}
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <Label for="password">{t('AUTH.LOCK.FORM.PASSWORD')}</Label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={toggleDisplayingPasswordText}
                    className={clsx(
                      'form-icon lg form-icon-right passcode-switch',
                      displayPasswordText ? 'is-hidden' : 'is-shown',
                    )}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <Input
                    type={displayPasswordText ? 'text' : 'password'}
                    id="password"
                    placeholder={t('AUTH.LOGIN.FORM.ENTER_PASSWORD')}
                    autoComplete="off"
                    className={clsx(
                      'form-control-lg',
                      displayPasswordText ? 'is-hidden' : 'is-shown',
                    )}
                    innerRef={passwordRef}
                    {...registerPassword}
                  />
                  {errors.password && (
                    <span className="invalid">{errors.password.message}</span>
                  )}
                </div>
              </FormGroup>
              {requiresMFA && (
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label" for="email">
                      {t('AUTH.LOGIN.FORM.MFA')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      id="email"
                      placeholder={t('AUTH.LOGIN.FORM.ENTER_MFA')}
                      className="form-control-lg"
                      innerRef={mfaRef}
                      {...registerMFA}
                      tabIndex={1}
                    />
                    {errors.mfa && (
                      <span className="invalid">{errors.mfa.message}</span>
                    )}
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t('AUTH.LOCK.BUTTON')
                  )}
                </Button>
              </FormGroup>
              <div className="form-note-s2 pt-4 text-center">
                <Link
                  to="/auth/login"
                  className="link link-primary"
                  onClick={() => dispatch(logout() as any)}
                >
                  {t('AUTH.LOCK.LOGIN_LINK')}
                </Link>
              </div>
            </Form>
          </PreviewCard>
        </Block>
      </AuthLayout>
    </>
  );
};

export default LockPage;
