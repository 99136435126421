import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import { PdfFilePreviewer } from '@oproma/pdfjs';
import { ProtectionSpecLevelEnum } from '@oproma/prividox-orchestration-open-api';
import {
  fetchAccessLevelForUser,
  filesService,
  getChildEntities,
  getGrantedVerbs,
  getWorkspaceEntities,
  protectionTag,
  setDisplayConvertConfirm,
  setDisplayDistributeEntityModal,
  toggleDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  InputGroup,
  InputGroupText,
  Modal,
} from 'reactstrap';
import * as yup from 'yup';
import { Icon } from '../../icon.component';
import { MediaPreview, TextPreview } from '../../preview';
import { Select } from '../../select.component';
import { prettyBytes } from '../../utils';
import { securityLevels } from '../constants';
import { FileManagerBreadcrumbs } from '../file-manager-breadcrumbs.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from '../svg-thumbnails.component';

const securityLevelSchema = yup
  .object({
    securityLevel: yup.string(),
  })
  .required();

type SecurityLevelFormValues = yup.InferType<typeof securityLevelSchema>;

export const FileManagerPreviewModal = () => {
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const { t } = useTranslation();
  const { accessLevel, metadata } = useAppSelector((state) => state.workspace);
  const { displayPreviewFileModal, lastOpenedEntity, changedSecurityLevel } =
    useAppSelector((state) => state.fileManager);
  const { verbs } = useAppSelector((state) => state.permissions);
  const { mobileMode } = useAppSelector((state) => state.layout);
  const { starred } = useAppSelector((state) => state.starred);
  const { entityId } = useParams();
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === state.workspaces.lastOpenedWorkspace,
    ),
  );
  const cardTextRef = useRef(null);

  const securityLevel = lastOpenedEntity?.level;

  const { watch, setValue } = useForm<SecurityLevelFormValues>({
    resolver: yupResolver(securityLevelSchema),
    defaultValues: {},
  });
  const togglePreviewModal = () => dispatch(toggleDisplayPreviewFileModal());
  const changeableSecurityLevel = watch('securityLevel');

  useEffect(() => {
    dispatch(
      fetchAccessLevelForUser(workspaceId ?? openedWorkspace?.id ?? 'N/A'),
    );
  }, [openedWorkspace?.id, workspaceId]);

  useEffect(() => {
    dispatch(getGrantedVerbs({ entity: lastOpenedEntity?.id as string }));
  }, [lastOpenedEntity?.id]);

  const handleLevelChange = () => {
    if (lastOpenedEntity) {
      dispatch(
        protectionTag({
          entity: lastOpenedEntity.id,
          protectionSpec: {
            level: changeableSecurityLevel as ProtectionSpecLevelEnum,
          },
        }),
      );
      if (changedSecurityLevel) {
        toast.success(
          `${t(
            'FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.TOAST',
          )} ${translatedFormatLabel(changeableSecurityLevel)}`,
        );
      }
      if (entityId) {
        dispatch(
          getChildEntities({
            entityId: entityId,
          }),
        );
      } else {
        dispatch(getWorkspaceEntities(workspaceId as string));
      }
    }
  };

  if (!openedWorkspace || !lastOpenedEntity) return;

  const redactions =
    !mobileMode &&
    metadata?.allowRedactions &&
    openedWorkspace.allowRedaction &&
    verbs.items?.includes('Write') &&
    accessLevel?.toString() !== '-128';

  const handleOnDownloadClick = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (lastOpenedEntity) {
      try {
        // TODO: Refactor this as this is duplicated from file-manager-dropdown.component.tsx
        const toastId = toast.info(
          `${t('FILE_MANAGER.TOASTS.DOWNLOADING_ENTITY')} ${
            lastOpenedEntity.name
          }: 0%`,
          {
            autoClose: false,
          },
        );
        const onProgress = (progress: number) => {
          toast.update(toastId, {
            render: `${t('FILE_MANAGER.TOASTS.DOWNLOADING_ENTITY')} ${
              lastOpenedEntity.name
            }: ${progress.toFixed(2)}%`,
          });
        };
        const fileContents = await filesService.fetchFile(
          lastOpenedEntity.id,
          onProgress,
        );
        if (!fileContents) throw new Error('Failed to download file');
        const url = URL.createObjectURL(fileContents);
        const link = document.createElement('a');
        link.href = url;
        link.download = lastOpenedEntity.name ?? 'prividox-file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(`Downloaded ${lastOpenedEntity.name}!`);
      } catch (error) {
        console.error(error);
        toast.error(`Failed to download ${lastOpenedEntity.name}`);
      }
    }
  };

  const handleOnCovertClick = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    dispatch(setDisplayConvertConfirm(true));
  };

  const translatedFormatLabel = (data: string | undefined) => {
    switch (data) {
      case 'PROTECTED_A':
        return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_A');
      case 'NONE':
        return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.NONE');
      case 'PROTECTED_B':
        return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_B');
      default:
        return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.NONE');
    }
  };

  const securityOptions = securityLevels.map((level) => ({
    value: level.value,
    label: t(level.label),
  }));

  return (
    <Modal
      isOpen={displayPreviewFileModal}
      size="xl"
      toggle={togglePreviewModal}
    >
      <div className="modal-header align-center">
        <div className="nk-file-title">
          <div className="nk-file-icon">
            <div className="nk-file-icon-type">
              {getEntityThumbnail(
                (lastOpenedEntity?.contentType ??
                  lastOpenedEntity?.type ??
                  'others') as EntityThumbnail,
              )}
            </div>
          </div>
          <div className="nk-file-name">
            <div className="nk-file-name-text">
              <span className="title">{lastOpenedEntity?.name}</span>
            </div>
            <div className="nk-file-name-sub">
              {lastOpenedEntity?.type?.toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          togglePreviewModal();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body">
        {lastOpenedEntity?.type === 'file' &&
          openedWorkspace?.access === 'MIXED' &&
          (accessLevel?.toString() === '127' ||
            accessLevel?.toString() === '126' ||
            accessLevel?.toString() === '0') && (
            <div className="nk-file-details-header d-flex justify-content-between mb-3">
              <div className="nk-file-details-input nk-file-details-input-to">
                <InputGroup>
                  <InputGroupText>
                    {t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.TITLE')}
                  </InputGroupText>
                  <Select
                    options={securityOptions}
                    className="input-mail w-200px"
                    placeholder={translatedFormatLabel(securityLevel)}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    onChange={(ev) => setValue('securityLevel', ev?.value)}
                  ></Select>
                </InputGroup>
              </div>
              <Button color="primary" size="md" onClick={handleLevelChange}>
                {t('FILE_MANAGER.FILE_PREVIEW.CONFIRM')}
              </Button>
            </div>
          )}

        {lastOpenedEntity?.type === 'file' && (
          <Card className="card-bordered mb-2">
            <CardHeader className="border-bottom">
              {t('FILE_MANAGER.FILE_PREVIEW.TITLE')}
            </CardHeader>
            <CardBody
              className="card-inner"
              style={{
                height: 'calc(80vh - 40px)',
                padding: 0,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardText
                ref={cardTextRef}
                className="dynamic-card-text"
                style={{
                  flexGrow: 1,
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {(lastOpenedEntity?.contentType === 'application/pdf' ||
                  lastOpenedEntity?.contentType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  lastOpenedEntity?.contentType ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  lastOpenedEntity?.contentType ===
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  lastOpenedEntity?.contentType === 'application/msword' ||
                  lastOpenedEntity?.contentType ===
                    'application/vnd.ms-excel' ||
                  lastOpenedEntity?.contentType ===
                    'application/vnd.ms-powerpoint') && (
                  <div
                    className="pdf-preview-container"
                    style={{
                      width: '100%',
                      flexGrow: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <PdfFilePreviewer
                      entity={lastOpenedEntity}
                      style={{ width: '100%', height: '100%', flexGrow: 1 }}
                      redactions={redactions as boolean}
                    />
                  </div>
                )}
                {(lastOpenedEntity?.contentType?.startsWith('text') ||
                  (lastOpenedEntity?.contentType?.startsWith('application') &&
                    !(
                      lastOpenedEntity?.contentType === 'application/pdf'
                    ))) && <TextPreview entity={lastOpenedEntity} />}
                {(lastOpenedEntity?.contentType?.startsWith('image') ||
                  lastOpenedEntity?.contentType?.startsWith('img')) && (
                  <MediaPreview entity={lastOpenedEntity} />
                )}
              </CardText>
            </CardBody>
          </Card>
        )}

        <div className="nk-file-details">
          {lastOpenedEntity?.type === 'file' && (
            <>
              <div className="nk-file-details-row">
                <div className="nk-file-details-col">
                  {t('FILE_MANAGER.FILE_PREVIEW.STORAGE')}
                </div>
                <div className="nk-file-details-col">
                  {prettyBytes(lastOpenedEntity?.metadata?.size ?? 0)}
                </div>
              </div>

              <div className="nk-file-details-row">
                <div className="nk-file-details-col">
                  {t('FILE_MANAGER.FILE_PREVIEW.VERSION')}
                </div>
                <div className="nk-file-details-col">
                  v{lastOpenedEntity?.metadata?.numversion ?? 0}
                </div>
              </div>
            </>
          )}
          <div className="nk-file-details-row">
            <div className="nk-file-details-col">
              {t('FILE_MANAGER.FILE_PREVIEW.LOCATION')}
            </div>
            <div className="nk-file-details-col">
              <FileManagerBreadcrumbs type="preview" />
            </div>
          </div>
          <div className="nk-file-details-row">
            <div className="nk-file-details-col">
              {t('FILE_MANAGER.FILE_PREVIEW.OWNER')}
            </div>
            <div className="nk-file-details-col">
              {lastOpenedEntity?.creatorname}
            </div>
          </div>
          <div className="nk-file-details-row">
            <div className="nk-file-details-col">
              {t('FILE_MANAGER.FILE_PREVIEW.STARRED')}
            </div>
            <div className="nk-file-details-col">
              {starred.includes(lastOpenedEntity?.id ?? '') ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="nk-file-details-row">
            <div className="nk-file-details-col">
              {t('FILE_MANAGER.FILE_PREVIEW.LAST_MODIFIED')}
            </div>
            <div className="nk-file-details-col">
              <Moment format="l hh:mm a">{lastOpenedEntity?.modified}</Moment>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-stretch bg-light">
        <div className="modal-footer-between d-flex justify-content-between">
          <div className="d-flex">
            <a
              href="#file-share"
              onClick={(ev) => {
                ev.preventDefault();
                dispatch(setDisplayDistributeEntityModal(true));
              }}
              className="btn btn-outline-light btn-white me-2"
            >
              {t('FILE_MANAGER.FILE_PREVIEW.SHARE')}
            </a>
            <a
              href="#file-share"
              onClick={handleOnCovertClick}
              className="btn btn-outline-light btn-white me-2"
            >
              Convert
            </a>
            <a
              href="link"
              onClick={handleOnDownloadClick}
              className="btn btn-primary"
            >
              {t('FILE_MANAGER.FILE_PREVIEW.DOWNLOAD')}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};
