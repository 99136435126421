import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import { UploadLevelEnum } from '@oproma/prividox-orchestration-open-api';
import {
  createFile,
  createFolder,
  FileWithPath,
  FolderTypeEnum,
  getChildEntities,
  getEntityMetadata,
  getEntityParents,
  setIsMinimized,
  setQueuedFiles,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputGroup, InputGroupText } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { securityLevels } from '../constants';
import {
  FileTreeNode,
  FileTreeNodes,
} from '../file-manager-file-tree-node.component';

const securityLevelSchema = yup
  .object({
    securityLevel: yup.string().default('NONE'),
  })
  .required();

type SecurityLevelFormValues = yup.InferType<typeof securityLevelSchema>;

export const FileManagerCreateFileModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessLevel } = useAppSelector((state) => state.workspace);

  // State management
  const [queuedFolders, setQueuedFolders] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [publishableUids, setPublishableUids] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  // Refs
  const modalContentRef = useRef<HTMLDivElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const abortControllersRef = useRef<{ [key: string]: AbortController }>({});

  // Selectors
  const { workspaceId, entityId } = useParams();
  const { queuedFiles, isMinimized } = useAppSelector(
    (state) => state.fileManager,
  );
  const uploadStates = useAppSelector(
    (state) => state.fileManager.uploadStates,
  );
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    ),
  );

  const isUploadEnabled = Boolean(workspaceId || entityId);

  // Get the combined upload progress across all files
  const totalProgress = useMemo(() => {
    if (!publishableUids.length) return 0;
    const progress = publishableUids.reduce((acc, uid) => {
      return acc + (uploadStates[uid]?.progress || 0);
    }, 0);
    return Math.round(progress / publishableUids.length);
  }, [publishableUids, uploadStates]);

  // Check if any files are currently uploading
  const isAnyFileUploading = useMemo(() => {
    return publishableUids.some(
      (uid) => uploadStates[uid]?.status === 'uploading',
    );
  }, [publishableUids, uploadStates]);

  const toggleMinimize = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    dispatch(setIsMinimized(!isMinimized));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMinimized) return;

      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node) &&
        !uploading &&
        queuedFiles.length === 0
      ) {
        setIsMinimized(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMinimized, uploading, queuedFiles.length]);

  const { watch, setValue } = useForm<SecurityLevelFormValues>({
    resolver: yupResolver(securityLevelSchema),
    defaultValues: {
      securityLevel: 'NONE',
    },
  });

  const buildFileTree = (files: FileWithPath[]): FileTreeNode[] => {
    const root: FileTreeNode[] = [];
    const folderMap = new Map<string, FileTreeNode>();

    files.forEach((file) => {
      const filepath = (file as FileWithPath).filepath || file.name;
      const parts = filepath.split('/');
      let currentPath = '';
      let currentLevel = 0;

      parts.forEach((part, index) => {
        const isFile = index === parts.length - 1;
        currentPath = currentPath ? `${currentPath}/${part}` : part;

        if (isFile) {
          const node: FileTreeNode = {
            name: part,
            type: 'file',
            file,
            filepath,
            level: currentLevel,
          };

          if (parts.length === 1) {
            root.push(node);
          } else {
            const parentPath = parts.slice(0, -1).join('/');
            const parent = folderMap.get(parentPath);
            if (parent) {
              parent.children = parent.children || [];
              parent.children.push(node);
            }
          }
        } else {
          if (!folderMap.has(currentPath)) {
            const node: FileTreeNode = {
              name: part,
              type: 'folder',
              children: [],
              level: currentLevel,
            };
            folderMap.set(currentPath, node);

            if (currentLevel === 0) {
              root.push(node);
            } else {
              const parentPath = parts.slice(0, index).join('/');
              const parent = folderMap.get(parentPath);
              if (parent) {
                parent.children = parent.children || [];
                parent.children.push(node);
              }
            }
          }
        }
        currentLevel++;
      });
    });

    return root;
  };

  const changeableSecurityLevel = watch('securityLevel');

  const getTargetFolder = async (folderId: string): Promise<string> => {
    try {
      const metadata = await dispatch(getEntityMetadata(folderId)).unwrap();
      return metadata.id ?? folderId;
    } catch (error) {
      console.error('Failed to get entity metadata:', error);
      return folderId;
    }
  };

  const handleFileUpload = async (file: FileWithPath, folderId: string) => {
    const publishUid = uuidv4();

    if (!folderId) return;

    const target = await getTargetFolder(folderId);
    if (!target) return;

    const abortController = new AbortController();
    abortControllersRef.current[publishUid] = abortController;

    try {
      await dispatch(
        createFile({
          processUid: publishUid,
          file,
          metadata: {
            target,
            count: 1,
            encrypted: false,
            filesize: file.size,
            index: 0,
            name: file.name,
            original: target,
            upload: publishUid,
            body: {},
            level: changeableSecurityLevel as UploadLevelEnum,
          },
          signal: abortController.signal,
          t,
        }),
      ).unwrap();

      setPublishableUids((prev) => [...prev, publishUid]);

      // Refresh the file list
      dispatch(getEntityMetadata(entityId || (workspaceId as string)));
      dispatch(getEntityParents(entityId || (workspaceId as string)));
      dispatch(
        getChildEntities({
          entityId: entityId || (workspaceId as string),
        }),
      );
    } catch (error) {
      console.error('File upload failed:', error);
      // Error handling is now managed by the createFile thunk
    }
  };

  const cancelUpload = (processUid: string) => {
    if (abortControllersRef.current[processUid]) {
      abortControllersRef.current[processUid].abort();
      delete abortControllersRef.current[processUid];
    }
  };

  const cancelAllUploads = () => {
    Object.keys(abortControllersRef.current).forEach(cancelUpload);
  };

  const handleFolderCreation = async (
    parentFolderId: string,
    folderName: string,
  ): Promise<string | null> => {
    try {
      const targetFolder = await getTargetFolder(parentFolderId);
      const result = await dispatch(
        createFolder({
          entity: targetFolder,
          newItemSpec: {
            name: folderName,
          },
          type: FolderTypeEnum.GENERAL,
        }),
      ).unwrap();
      return result;
    } catch (error) {
      console.error('Folder creation failed:', error);
      // Handle error (e.g., show error message)
      return null;
    }
  };

  const processEntry = async (entry: FileSystemEntry, path = '') => {
    if (entry.isFile) {
      const file = await new Promise<File>((resolve) =>
        (entry as FileSystemFileEntry).file(resolve),
      );
      const fullPath = path ? `${path}/${file.name}` : file.name;
      const fileWithPath = Object.assign(file, {
        filepath: fullPath,
      }) as FileWithPath;

      // Update the queuedFiles array by adding the new file
      const updatedQueuedFiles = [...queuedFiles, fileWithPath];
      dispatch(setQueuedFiles(updatedQueuedFiles));
    } else if (entry.isDirectory) {
      const reader = (entry as FileSystemDirectoryEntry).createReader();
      const entries = await new Promise<FileSystemEntry[]>((resolve) =>
        reader.readEntries(resolve),
      );

      const newPath = path ? `${path}/${entry.name}` : entry.name;
      setQueuedFolders((prev) => [...new Set([...prev, newPath])]);

      await Promise.all(entries.map((entry) => processEntry(entry, newPath)));
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (!isUploadEnabled) {
      toast.error(t('FILE_MANAGER.ERRORS.INVALID_LOCATION'));
      return;
    }

    try {
      const items = Array.from(event.dataTransfer.items);
      if (items.length === 0) return;

      setUploading(true);

      // Process all entries
      await Promise.all(
        items.map((item) => {
          const entry = item.webkitGetAsEntry();
          if (entry) {
            return processEntry(entry);
          }
          return Promise.resolve();
        }),
      );
    } catch (error) {
      console.error('Drop handling failed:', error);
      toast.error(t('FILE_MANAGER.ERRORS.DROP_FAILED'));
    } finally {
      setUploading(false);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const persistFiles = async () => {
    if (!isUploadEnabled) {
      toast.error(t('FILE_MANAGER.ERRORS.INVALID_LOCATION'));
      return;
    }

    if (queuedFiles.length === 0) {
      toast.warn(t('FILE_MANAGER.UPLOAD_MODAL.NO_FILES_SELECTED'));
      return;
    }

    setUploading(true);

    const rootFolderId = entityId || openedWorkspace?.root || '';
    const folderMap = new Map<string, string>();
    folderMap.set('', rootFolderId);

    try {
      // Create folders first
      const sortedFolders = [...new Set(queuedFolders)].sort(
        (a, b) => a.split('/').length - b.split('/').length,
      );

      // Show folder creation progress
      if (sortedFolders.length > 0) {
        toast.info(t('FILE_MANAGER.UPLOAD_MODAL.CREATING_FOLDERS'));
      }

      for (const folderPath of sortedFolders) {
        const pathParts = folderPath.split('/');
        let parentPath = '';
        let parentId = rootFolderId;

        for (let i = 0; i < pathParts.length; i++) {
          const currentPart = pathParts[i];
          const currentPath = parentPath
            ? `${parentPath}/${currentPart}`
            : currentPart;

          if (!folderMap.has(currentPath)) {
            const newFolderId = await handleFolderCreation(
              parentId,
              currentPart,
            );
            if (!newFolderId) {
              throw new Error(`Failed to create folder: ${currentPath}`);
            }
            folderMap.set(currentPath, newFolderId);
          }

          parentId = folderMap.get(currentPath) || rootFolderId;
          parentPath = currentPath;
        }
      }

      // Upload files to their respective folders
      for (const file of queuedFiles) {
        const filePath = (file as FileWithPath).filepath || file.name;
        const pathParts = filePath.split('/');
        const fileName = pathParts.pop()!;
        const folderPath = pathParts.join('/');
        const parentId = folderMap.get(folderPath) || rootFolderId;

        await handleFileUpload(file, parentId);
      }

      setQueuedFolders([]);
      dispatch(setQueuedFiles([]));
      setPublishableUids([]);
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error(t('FILE_MANAGER.ERRORS.UPLOAD_FAILED'));
      cancelAllUploads();
    } finally {
      setUploading(false);
    }
  };

  const removeFromList = (filepath: string, index: number) => {
    const updatedFiles = queuedFiles.filter(
      (item) => item.filepath !== filepath,
    );
    dispatch(setQueuedFiles(updatedFiles));

    const updatedUploadIds = [...publishableUids];
    updatedUploadIds.splice(index, 1);
    setPublishableUids(updatedUploadIds);
  };

  const translatedSecurityLevels = securityLevels.map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  // Add upload status indicators for minimized state
  const getMinimizedStatus = () => {
    if (isAnyFileUploading) {
      return {
        icon: 'loader',
        className: 'animate-spin',
        title: t('FILE_MANAGER.UPLOAD_MODAL.UPLOADING'),
      };
    }
    return {
      icon: 'upload-cloud',
      className: '',
      title: t('FILE_MANAGER.UPLOAD_MODAL.TITLE'),
    };
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  // Add handler for file input change
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const files = Array.from(event.target.files).map(
      (file) => Object.assign(file, { filepath: file.name }) as FileWithPath,
    );

    dispatch(setQueuedFiles([...queuedFiles, ...files]));
  };

  const handleDropzoneClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      className={clsx('floating-uploader', {
        'is-minimized': isMinimized,
      })}
    >
      {isMinimized ? (
        <div
          className={clsx('minimized-button', {
            disabled: !isUploadEnabled,
            'is-uploading': isAnyFileUploading,
          })}
          onClick={isUploadEnabled ? toggleMinimize : undefined}
          title={
            !isUploadEnabled
              ? t('FILE_MANAGER.ERRORS.INVALID_LOCATION')
              : getMinimizedStatus().title
          }
        >
          <Icon
            name={getMinimizedStatus().icon}
            className={getMinimizedStatus().className}
          />
          {queuedFiles.length > 0 && (
            <span className="upload-count">{queuedFiles.length}</span>
          )}
          {isAnyFileUploading && (
            <div className="upload-progress">
              <div
                className="upload-progress-bar"
                style={{ width: `${totalProgress}%` }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="uploader-content">
          <div className="header-actions">
            <a
              href="#minimize"
              onClick={toggleMinimize}
              className="minimize-btn"
              title={t('COMMON.MINIMIZE')}
            >
              <Icon name="shrink"></Icon>
            </a>
          </div>
          <div ref={modalContentRef} className="content-body">
            {!isUploadEnabled ? (
              <div className="alert alert-danger">
                {t('FILE_MANAGER.ERRORS.INVALID_LOCATION')}
              </div>
            ) : (
              <>
                <div className="nk-upload-form">
                  <h5 className="title mb-3">
                    {t('FILE_MANAGER.UPLOAD_MODAL.TITLE')}
                  </h5>
                  {openedWorkspace?.access === 'MIXED' &&
                    (accessLevel?.toString() === '127' ||
                      accessLevel?.toString() === '126' ||
                      accessLevel?.toString() === '0') && (
                      <div className="nk-file-details-header d-flex justify-content-between">
                        <div className="nk-file-details-input nk-file-details-input-to">
                          <InputGroup>
                            <InputGroupText>
                              {t(
                                'FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.TITLE',
                              )}
                            </InputGroupText>
                            <Select
                              options={translatedSecurityLevels}
                              className="input-mail w-200px"
                              placeholder={translatedSecurityLevels[0].label}
                              closeMenuOnSelect={true}
                              isSearchable={false}
                              onChange={(ev) =>
                                ev?.value && setValue('securityLevel', ev.value)
                              }
                            ></Select>
                          </InputGroup>
                        </div>
                      </div>
                    )}
                  <div
                    ref={dropzoneRef}
                    className={clsx(
                      'dropzone upload-zone small bg-lighter dz-clickable my-2',
                      { 'is-dragging': isDragging },
                    )}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onClick={handleDropzoneClick}
                    style={{ cursor: 'pointer' }}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      style={{ display: 'none' }}
                      multiple
                    />
                    <div className="dz-message">
                      <span
                        className="dz-message-text"
                        dangerouslySetInnerHTML={{
                          __html: t('FILE_MANAGER.UPLOAD_MODAL.PLACEHOLDER'),
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="nk-upload-list">
                  <h5 className="title">
                    {t('FILE_MANAGER.UPLOAD_MODAL.HEADING')}
                  </h5>
                  {queuedFiles.length > 0 ? (
                    <SimpleBar style={{ maxHeight: '300px' }}>
                      <div className="file-tree">
                        {buildFileTree(queuedFiles).map((node, index) => (
                          <FileTreeNodes
                            key={index}
                            node={node}
                            onRemove={removeFromList}
                            index={index}
                            processUid={publishableUids[index]}
                            cancelUpload={cancelUpload}
                          />
                        ))}
                      </div>
                    </SimpleBar>
                  ) : (
                    <div className="d-flex justify-center">
                      <p className="text-soft">
                        {t('FILE_MANAGER.UPLOAD_MODAL.NO_FILES')}
                      </p>
                    </div>
                  )}
                </div>
                <div className="nk-modal-action justify-end">
                  <ul className="btn-toolbar g-4 align-center">
                    {isAnyFileUploading && (
                      <li>
                        <Button color="warning" onClick={cancelAllUploads}>
                          {t('FILE_MANAGER.UPLOAD_MODAL.CANCEL_ALL')}
                        </Button>
                      </li>
                    )}
                    <li>
                      <Button
                        color="primary"
                        onClick={persistFiles}
                        disabled={uploading || queuedFiles.length === 0}
                      >
                        {uploading ? (
                          <div className="d-flex align-items-center">
                            <Icon name="loader" className="animate-spin me-2" />
                            {t('FILE_MANAGER.UPLOAD_MODAL.UPLOADING')}
                          </div>
                        ) : (
                          t('FILE_MANAGER.UPLOAD_MODAL.UPLOAD_BUTTON')
                        )}
                      </Button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
