import { yupResolver } from '@hookform/resolvers/yup';
import {
  createDeviceFingerPrint,
  login,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useNavigate } from '@oproma/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

export const useLockForm = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.user);
  const { requiresMFA } = useAppSelector((state) => state.auth);

  const lockSchema = yup
    .object({
      password: yup.string().min(8).max(24).required(),
      mfa: requiresMFA ? yup.string().required() : yup.string(),
    })
    .required();

  type LockFormValues = yup.InferType<typeof lockSchema>;

  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<LockFormValues>({ resolver: yupResolver(lockSchema) });

  const { ref: passwordRef, ...registerPassword } = registerField('password');
  const { ref: mfaRef, ...registerMFA } = registerField('mfa');

  const onFormSubmit: SubmitHandler<LockFormValues> = ({ password, mfa }) => {
    if (!email) {
      return router('/auth/login', { replace: true });
    }
    dispatch(
      login({
        email,
        pass: password,
        fingerprint: createDeviceFingerPrint(),
        exp: 1800000, // expiry time in milliseconds
        token: mfa,
      }),
    );
  };

  return {
    errors,
    email,
    requiresMFA,
    mfaRef,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerPassword,
    registerMFA,
    displayPasswordText,
    toggleDisplayingPasswordText,
  };
};
